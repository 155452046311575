<template>
  <div
    class="surface-section px-4 py-8 md:px-6 lg:px-8 text-center flex flex-column justify-content-center align-items-center"
  >
    <div v-if="loading">
      <ProgressSpinner />
      <p class="text-lg">
        En unos momentos será redirigido a la página para actualizar los datos
        de cobro.
      </p>
    </div>
    <p class="p-error" v-else-if="error" v-html="error" />
    <p class="text-lg" v-else>
      Si no ha sido redirigido automaticamente haga click
      <a :href="redirect">aquí</a>
    </p>
  </div>
</template>

<script>
import api from '../api/Acutame';

export default {
  data() {
    return {
      paymentId: this.$route.params.paymentId,
      loading: true,
      error: null,
      redirect: null,
    };
  },
  mounted() {
    const getPaymentUpdateUrl = async () => {
      try {
        const { url } = await api.updatePricingPlan(this.paymentId);
        window.top.location.assign(url);
        this.redirect = url;
      } catch (error) {
        console.error(error);
        this.error = `Ha habido un error inesperado. Por favor contacte al soporte indicando el siguiente identificador del pago <strong>${this.paymentId}</strong>`;
      }
      this.loading = false;
    };
    getPaymentUpdateUrl();
  },
};
</script>

<style></style>
